import { Card, CardContent } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import {
  FilterList,
  FilterListItem,
  SavedQueriesList,
  useListContext,
  useGetList,
} from 'react-admin';
import { useGetFilters } from './SettlementMappingFilters';

const SettlementMappingListAside = () => {
  const { filterValues } = useListContext();
  const { settlementTypes, rangeTypes, paymentTypes } = useGetFilters();

  const { data: cardTypes, isLoading: isCardTypesLoading } = useGetList(
    'cardTypes',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  );

  const { data: cardBankTypes, isLoading: isCardBankTypesLoading } = useGetList(
    'cardBankTypes',
    {
      pagination: { page: 1, perPage: 100 },
      sort: { field: 'name', order: 'ASC' },
    }
  );

  // Filter cardBankTypes based on selected cardType
  const filteredCardBankTypes = cardBankTypes?.filter((bankType) => {
    return bankType.cardTypeId === filterValues?.cardTypeId;
  });

  // Show Card Bank Types only when cardType is selected and filtered results exist
  const showCardBankTypes =
    !!filterValues?.cardTypeId &&
    !isCardBankTypesLoading &&
    filteredCardBankTypes &&
    filteredCardBankTypes.length > 0;

  return (
    <Card
      sx={{
        order: -1,
        flex: '0 0 15em',
        mr: 2,
        mt: 6,
        alignSelf: 'flex-start',
        height: '85vh',
        overflowY: 'auto',
      }}
    >
      <CardContent sx={{ pt: 1 }}>
        <SavedQueriesList />

        <FilterList label='Payment Type' icon={<CheckCircleOutlineIcon />}>
          {paymentTypes.map((paymentType) => (
            <FilterListItem
              key={paymentType.id}
              label={paymentType.desc}
              value={{ paymentTypeId: paymentType.id }}
            />
          ))}
        </FilterList>

        <FilterList label='Card Type' icon={<CurrencyRupeeIcon />}>
          {!isCardTypesLoading &&
            cardTypes &&
            cardTypes.map((cardType) => (
              <FilterListItem
                key={cardType.id}
                label={cardType.name}
                value={{ cardTypeId: cardType.id }}
              />
            ))}
        </FilterList>

        {showCardBankTypes && (
          <FilterList label='Card Bank Type' icon={<CurrencyRupeeIcon />}>
            {filteredCardBankTypes.map((cardBankType) => (
              <FilterListItem
                key={cardBankType.id}
                label={cardBankType.name}
                value={{ cardBankTypeId: cardBankType.id }}
              />
            ))}
          </FilterList>
        )}

        <FilterList label='Range Type' icon={<CurrencyRupeeIcon />}>
          {rangeTypes.map((rangeType) => (
            <FilterListItem
              key={rangeType.id}
              label={rangeType.desc}
              value={{ rangeTypId: rangeType.id }}
            />
          ))}
        </FilterList>
      </CardContent>
    </Card>
  );
};

export default SettlementMappingListAside;
