import * as React from 'react';
import { useCallback } from 'react';
import {
  AutocompleteInput,
  DatagridConfigurable,
  DateField,
  Datagrid,
  DateInput,
  TimeInput,
  ExportButton,
  FilterButton,
  List,
  NumberField,
  ReferenceInput,
  SelectColumnsButton,
  TextField,
  TextInput,
  NumberInput,
  TopToolbar,
  useListContext,
  downloadCSV,
  SelectInput,
  DateTimeInput,
  FunctionField,
  SearchInput,
  useNotify,
  useDataProvider,
  useGetMany,
  CreateButton,
} from 'react-admin';
import {
  useMediaQuery,
  Divider,
  Theme,
  Typography,
  Box,
  Icon,
  Stack,
  Button,
} from '@mui/material';
import { formatDistanceStrict } from 'date-fns';
import CustomerReferenceField from '../customers/CustomerReferenceField';
import { Customer, Order, paymentStatusTitles } from '../types';
import OrderListAside from './OrderListAside';
import jsonExport from 'jsonexport/dist';
import CheckCircleOutline from '@mui/icons-material/CheckCircleOutline';
import PauseCircleOutline from '@mui/icons-material/PauseCircle';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import {
  CheckCircle,
  TimerOutlined,
  WarningAmberOutlined,
  LinkOutlined,
  LinkOff,
} from '@mui/icons-material';
import { getAmountWithoutFee, numberToPriceFormat } from '../helpers';
import ReceiptIcon from '@mui/icons-material/Receipt';

import { apiUrl } from '../constants';
import { stringify } from 'query-string';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';

export const renderPaymentStatus = (value: any) => {
  return (
    <Stack direction='row' alignItems='center' spacing={0.5}>
      {value.paymentStatus === 'RECEIVED' && (
        <CheckCircle fontSize='small' color='success' />
      )}
      {value.paymentStatus === 'INITIATED' && value.paymentLinkRequested && (
        <WhatsAppIcon fontSize='small' color='success' />
      )}
      {value.paymentStatus === 'SETTLEMENT_INITIATED' && (
        <TimerOutlined fontSize='small' color='warning' />
      )}
      {value.paymentStatus === 'SETTLEMENT_COMPLETED' && (
        <CheckCircleOutline fontSize='small' />
      )}
      {value.paymentStatus === 'ON_HOLD' && (
        <PauseCircleOutline fontSize='small' color='warning' />
      )}
      {((value.paymentStatus === 'INITIATED' &&
        !value.paymentLinkRequested &&
        value.pgDetail?.pgName !== 'MASTER PAY') ||
        value.paymentStatus === 'CANCELLED') && (
        <WarningAmberOutlined fontSize='small' />
      )}
      {value.paymentStatus === 'INITIATED' &&
        value.pgDetail?.pgName === 'MASTER PAY' &&
        (value.manualPaymentLink ? (
          <LinkOutlined fontSize='small' color='success' />
        ) : (
          <LinkOff fontSize='small' />
        ))}
      {value.paymentStatus === 'FAILED' && (
        <WarningAmberOutlined fontSize='small' color='error' />
      )}
      <Typography variant='body2'>
        {value.paymentStatus === 'INITIATED'
          ? value.pgDetail?.pgName === 'MASTER PAY'
            ? value.manualPaymentLink
              ? 'Link Generated'
              : 'Link Not Generated'
            : value.manualPaymentLink
            ? 'Link Added'
            : value.pgDetail?.pgName === 'MANUAL'
            ? 'Link Required'
            : paymentStatusTitles[value.paymentStatus]
          : paymentStatusTitles[value.paymentStatus]}
      </Typography>
      {value.paymentStatus === 'SETTLEMENT_COMPLETED' && value.settlementAt && (
        <Typography variant='body2'>
          {formatDistanceStrict(
            new Date(value.settlementAt),
            new Date(value.placedAt || value.createdAt),
            {
              addSuffix: true,
            }
          )}
        </Typography>
      )}
      {value.settlementBy && value.paymentStatus === 'RECEIVED' && (
        <Typography variant='body2'>
          {formatDistanceStrict(new Date(value.settlementBy), new Date(), {
            addSuffix: true,
          })}
        </Typography>
      )}
    </Stack>
  );
};

const InvoiceButton = ({ filterValues }: { filterValues: any }) => {
  const notify = useNotify();
  const handleInvoice = async () => {
    try {
      const response = await fetch(
        `${apiUrl}/orders/downloadZip?${stringify({
          filter: JSON.stringify(filterValues),
          // range: JSON.stringify([0, 24]),
        })}`,
        {
          method: 'GET',
          headers: {
            Authorization: localStorage.getItem('auth') || '',
          },
        }
      );

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Get filename from response headers or use default
      const filename =
        response.headers.get('content-disposition')?.split('filename=')[1] ||
        'invoices.zip';

      // Create blob from response
      const blob = await response.blob();

      // Create download link and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      notify('Invoice downloaded successfully', { type: 'success' });
    } catch (error) {
      console.error('Download error:', error);
      notify('Failed to download invoice', { type: 'error' });
    }
  };

  return (
    <Button size='small' startIcon={<ReceiptIcon />} onClick={handleInvoice}>
      Invoice
    </Button>
  );
};

const ListActions = () => {
  const { filterValues } = useListContext();
  const navigate = useNavigate();

  return (
    <TopToolbar>
      {/* <SelectColumnsButton /> */}
      <CreateButton label='Create Offline Order' />

      <FilterButton />
      <ExportButton />
      <InvoiceButton filterValues={filterValues} />
    </TopToolbar>
  );
};

export const paymentStatusChoices = [
  { id: 'RECEIVED', name: 'Received' },
  { id: 'SETTLEMENT_INITIATED', name: 'Settlement Initiated' },
  { id: 'SETTLEMENT_COMPLETED', name: 'Settled' },
  { id: 'FAILED', name: 'Failed' },
  { id: 'CANCELLED', name: 'Cancelled' },
  { id: 'INITIATED', name: 'Incomplete' },
  { id: 'REFUND_INITIATED', name: 'Refunded' },
  { id: 'ON_HOLD', name: 'OnHold' },
];

export const paymentGatewayChoices = [
  { id: 'RUNPAISA', name: 'Runpaisa' },
  { id: 'MASTERPAY', name: 'Masterpay' },
  { id: 'PINELABS', name: 'Pinelabs' },
  { id: 'PAYOMATIX', name: 'Payomatix' },
  { id: 'MANUAL', name: 'Manual Link' },
  { id: 'SABPAISA', name: 'Sabpaisa' },
  { id: 'RAZORPAY', name: 'Razorpay' },
];

export const paymentTypeChoices = [
  { id: 'VENDOR_PAYMENT', name: 'Vendor Payment' },
  { id: 'RENTAL_PAYMENT', name: 'Rental Payment' },
  { id: 'EDUCATIONAL_PAYMENT', name: 'Educational Payment' },
];

export const settlementTypeChoices = [
  { id: 'INSTANT_PAY', name: 'Instant Pay' },
  { id: 'FAST_PAY', name: 'Fast Pay' },
  { id: 'REGULAR_PAY', name: 'Regular Pay' },
];

const orderFilters = [
  <SearchInput source='q' />,
  <ReferenceInput label='Customers' source='userId' reference='customers'>
    <AutocompleteInput
      optionText={(choice?: Customer) =>
        choice?.id // the empty choice is { id: '' }
          ? `${choice?.personalDetails?.firstName} ${choice?.personalDetails?.lastName}`
          : ''
      }
      sx={{ minWidth: 200 }}
    />
  </ReferenceInput>,
  <DateTimeInput label='Created After' source='createdAtGTE' />,
  <DateTimeInput label='Created Before' source='createdAtLTE' />,
  <NumberInput label='Total Above' source='settlementAmountGTE' />,
  <NumberInput label='Total Below' source='settlementAmountLTE' />,
  <SelectInput
    label='Payment Status'
    source='paymentStatus'
    choices={paymentStatusChoices}
  />,
  <SelectInput
    label='Payment Type'
    source='paymentType'
    choices={paymentTypeChoices}
  />,
  <SelectInput
    label='Settlement Type'
    source='settlementType'
    choices={settlementTypeChoices}
  />,
  <SelectInput
    label='Payment Gateway'
    source='pgName'
    choices={paymentGatewayChoices}
  />,
  <DateTimeInput label='Settlement After' source='settlementByGTE' />,
  <DateTimeInput label='Settlement Before' source='settlementByLTE' />,
];

// Create a wrapper component to handle the export
const OrderExporter = () => {
  const dataProvider = useDataProvider();

  const exporter = async (orders: Order[]) => {
    try {
      // Get unique customer IDs
      const customerIds = Array.from(
        new Set(orders.map((order) => order.userId))
      );

      // Fetch all customers in one request
      const { data: customers } = await dataProvider.getMany('customers', {
        ids: customerIds,
      });

      // Create a map for quick customer lookup
      const customerMap = customers.reduce((acc, customer) => {
        acc[customer.id] = customer;
        return acc;
      }, {} as Record<string, any>);

      const ordersForExport = orders.map((order) => {
        const customer = customerMap[order.userId] || {};
        const {
          id,
          priceBreakUp,
          clientPaymentId,
          clientReferenceId,
          currency,
          guestDetail,
          updatedAt,
          payeeInfo,
          pgDetail,
          ...rest
        } = order;

        // Preserve all existing fields and add customer details
        return {
          ...order, // Keep all original fields
          customerName: customer?.personalDetails
            ? `${customer.personalDetails.firstName} ${customer.personalDetails.lastName}`
            : '',
          customerEmail: customer?.personalDetails?.email || '',
          customerMobile: customer?.personalDetails?.mobile || '',
        };
      });

      jsonExport(
        ordersForExport,
        {
          headers: [
            'id',
            'clientReferenceId',
            'customerName',
            'customerEmail',
            'customerMobile',
            'paymentStatus',
            'paymentType',
            'settlementType',
            'createdAt',
            'priceBreakUp.totalAmount',
            'priceBreakUp.settlementAmount',
            'priceBreakUp.serviceFeeAmount',
            'priceBreakUp.gstAmount',
            'pgDetail.pgName',
            'payeeInfo.payerName',
            'payeeInfo.accountNo',
            'payeeInfo.accountIFSCCode',
            'payeeInfo.vpa',
          ],
        },
        (_err: any, csv: any) => {
          downloadCSV(csv, 'orders');
        }
      );
    } catch (error) {
      console.error('Error exporting orders:', error);
    }
  };

  return exporter;
};

const OrderListContent = () => {
  const listContext = useListContext();
  const { filterValues, setFilters, displayedFilters, ...rest } = listContext;

  const totalCollectedAmount = listContext.data?.reduce(
    (acc: number, order: any) => {
      return acc + getAmountWithoutFee(order);
    },
    0
  );

  const totalSettlementAmount = listContext.data?.reduce(
    (acc: number, order: any) => {
      return acc + order.priceBreakUp.settlementAmount;
    },
    0
  );

  const totalProfit = Math.round(totalCollectedAmount - totalSettlementAmount);

  return (
    <Datagrid
      rowSx={(record, index) => {
        if (index === 2) {
          console.log('record', record);
          console.log(
            record.paymentStatus === 'INITIATED' &&
              ((!record.paymentLinkRequested &&
                record.pgDetail?.pgName !== 'MASTER PAY') ||
                (record.pgDetail?.pgName === 'MASTER PAY' &&
                  !record.manualPaymentLink))
          );
        }
        return {
          opacity:
            record.paymentStatus === 'FAILED' ||
            record.paymentStatus === 'CANCELLED' ||
            (record.paymentStatus === 'INITIATED' &&
              ((!record.paymentLinkRequested &&
                record.pgDetail?.pgName !== 'MASTER PAY') ||
                (record.pgDetail?.pgName === 'MASTER PAY' &&
                  !record.manualPaymentLink)))
              ? 0.5
              : 1,
          '& *': {
            fontWeight:
              record.paymentStatus === 'RECEIVED' ||
              (record.paymentStatus === 'INITIATED' &&
                ((record.paymentLinkRequested && !record.manualPaymentLink) ||
                  (record.pgDetail?.pgName === 'MASTER PAY' &&
                    record.manualPaymentLink)))
                ? 'bold !important'
                : 400,
          },
          '& .MuiTableCell-root': {
            whiteSpace: 'nowrap',
          },
        };
      }}
      bulkActionButtons={false}
      rowClick='edit'
      sx={{ opacity: 1 }}
    >
      <DateField
        source='createdAt'
        showTime
        options={{
          month: 'short',
          day: 'numeric',
          hour12: true,
          hour: 'numeric',
          minute: 'numeric',
        }}
      />
      {/* <TextField source="clientInvoiceId" label="Invoice ID" /> */}
      <FunctionField
        source='paymentStatus'
        label='Status'
        render={renderPaymentStatus}
      />
      <CustomerReferenceField link={false} />
      {/* <FunctionField
        source="priceBreakUp.serviceFeeAmount"
        label={`Profit (₹${numberToPriceFormat(totalProfit)})`}
        render={(record: any) => {
          const collected = getAmountWithoutFee(record);
          return `₹${Number(
            (collected - record.priceBreakUp.settlementAmount).toFixed(2)
          )}`;
        }}
      /> */}
      <FunctionField
        label='Settlement Amount'
        source='priceBreakUp.settlementAmount'
        render={(record: any) => {
          if (record?.adjustments?.settlementAmount) {
            return (
              <span>
                <span
                  style={{
                    textDecoration: 'line-through',
                    fontWeight: 'normal !important',
                    opacity: 0.4,
                    marginRight: '5px',
                  }}
                >{`₹${numberToPriceFormat(
                  record.priceBreakUp.settlementAmount
                )}`}</span>
                <span>{`₹${numberToPriceFormat(
                  record.adjustments.settlementAmount
                )}`}</span>
              </span>
            );
          }

          return `₹${numberToPriceFormat(
            record.priceBreakUp.settlementAmount
          )}`;
        }}
      />
      <NumberField
        label='Total Amount'
        source='priceBreakUp.totalAmount'
        options={{
          style: 'currency',
          currency: 'INR',
        }}
      />
    </Datagrid>
  );
};

const OrderList = () => {
  const exporter = OrderExporter();

  return (
    <>
      <List
        filterDefaultValues={{ paymentStatus: 'RECEIVED' }}
        sort={{ field: 'createdAt', order: 'DESC' }}
        perPage={25}
        filters={orderFilters}
        actions={<ListActions />}
        aside={<OrderListAside />}
        exporter={exporter}
      >
        <OrderListContent />
      </List>
    </>
  );
};

export default OrderList;
