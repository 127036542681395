import { SearchInput, SelectInput, useGetList } from 'react-admin';

export const useGetFilters = () => {
  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const { data: rangeTypes = [] } = useGetList('rangeTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: cardTypes = [] } = useGetList('cardTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: _cardBankTypes = [] } = useGetList('cardBankTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const cardBankTypes = Array.from(
    new Set(_cardBankTypes.map((st) => st.name))
  ).map((name) => _cardBankTypes.find((st) => st.name === name));

  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: merchantCodes = [] } = useGetList('merchantCodes', {
    pagination: { page: 1, perPage: 100 },
  });

  console.log(merchantCodes, 'pp');

  // const merchantCodes = _merchantCodes?.reduce((acc: any, item: any) => {
  //   if (!acc[item.merchantId]) {
  //     acc[item.merchantId] = item;
  //   }
  //   return acc;
  // });

  return {
    settlementTypes,
    rangeTypes,
    cardTypes,
    cardBankTypes,
    paymentTypes,
    merchantCodes,
  };
};

export const orderFilters = ({
  settlementTypes,
  rangeTypes,
  cardTypes,
  cardBankTypes,
  paymentTypes,
  merchantCodes,
}: any) => [
  <SelectInput label="Range Type" source="rangeTypId" choices={rangeTypes} />,
  <SelectInput
    label="Card Type"
    source="cardTypeId"
    choices={cardTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label="Card Bank Type"
    source="cardBankTypeId"
    choices={cardBankTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label="Payment Type"
    source="paymentTypeId"
    choices={paymentTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.desc}` : '')}
  />,
  <SelectInput
    label="Settlement Type"
    source="settlementTypeId"
    choices={settlementTypes.map((i: any) => ({
      id: i.settlementTypeId,
      name: i.description,
    }))}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label="Merchant Code"
    source="merchantCode"
    choices={merchantCodes}
    optionText={(choice?: any) =>
      choice?.id ? `${choice?.details || choice?.merchantId}` : ''
    }
  />,
];
