import { SelectInput, useGetList } from 'react-admin';

export const useGetFilters = () => {
  const { data: _settlementTypes = [] } = useGetList('settlementTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const settlementTypes = Array.from(
    new Set(_settlementTypes.map((st) => st.name))
  ).map((name) => _settlementTypes.find((st) => st.name === name));

  const { data: rangeTypes = [] } = useGetList('rangeTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: cardTypes = [] } = useGetList('cardTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const { data: _cardBankTypes = [] } = useGetList('cardBankTypes', {
    pagination: { page: 1, perPage: 100 },
  });
  const cardBankTypes = Array.from(
    new Set(_cardBankTypes.map((st) => st.name))
  ).map((name) => _cardBankTypes.find((st) => st.name === name));

  const { data: paymentTypes = [] } = useGetList('paymentTypes', {
    pagination: { page: 1, perPage: 100 },
  });

  return {
    settlementTypes,
    rangeTypes,
    cardTypes,
    cardBankTypes,
    paymentTypes,
  };
};

export const settlementFilters = ({
  settlementTypes,
  rangeTypes,
  cardTypes,
  cardBankTypes,
  paymentTypes,
}: any) => [
  <SelectInput label='Range Type' source='rangeTypId' choices={rangeTypes} />,
  <SelectInput
    label='Card Type'
    source='cardTypeId'
    choices={cardTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label='Card Bank Type'
    source='cardBankTypeId'
    choices={cardBankTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.name}` : '')}
  />,
  <SelectInput
    label='Payment Type'
    source='paymentTypeId'
    choices={paymentTypes}
    optionText={(choice?: any) => (choice?.id ? `${choice?.desc}` : '')}
  />,
];
