import OrderIcon from "@mui/icons-material/CurrencyRupee";

import OrderList from "./OrderList";
import OrderEdit from "./OrderEdit";
import OrderCreate from "./OrderCreate";

export default {
  list: OrderList,
  edit: OrderEdit,
  icon: OrderIcon,
  create: OrderCreate,
};
