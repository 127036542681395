import { loggedUser } from '../../authProvider';
import { apiUrl, nodeAPIUrl } from '../../constants';

export const createNote = (data: {
  text: string;
  itemId?: string;
  section: string;
}) => {
  return fetch(`${nodeAPIUrl}/notes?userId=${loggedUser.userId}`, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('auth') || '',
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

export const updateNote = (data: { text: string; noteId: string }) => {
  return fetch(
    `${nodeAPIUrl}/notes/${data.noteId}?userId=${loggedUser.userId}`,
    {
      method: 'PUT',
      body: JSON.stringify({ text: data.text }),
      headers: {
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('auth') || '',
      },
    }
  )
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

export const deleteNote = (noteId: string) => {
  return fetch(`${nodeAPIUrl}/notes/${noteId}?userId=${loggedUser.userId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('auth') || '',
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

export const updateReadNote = (payload: any) => {
  return fetch(`${nodeAPIUrl}/notes?userId=${loggedUser.userId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: localStorage.getItem('auth') || '',
    },
    body: JSON.stringify(payload),
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
    });
};

export const getNotes = ({
  itemId,
  section,
  currentPage,
  pageSize = 5,
  read,
}: {
  itemId?: string;
  section: string;
  currentPage: number;
  pageSize?: number;
  read?: string | undefined;
}) => {
  const range = [currentPage * pageSize, (currentPage + 1) * pageSize - 1];
  let _data = {} as any;
  return fetch(
    `${nodeAPIUrl}/notes?userId=${
      loggedUser?.userId
    }&filter={"section":"${section}"${itemId ? `,"itemId":"${itemId}"` : ''}${
      read ? `,"read":${read}` : ''
    }}&range=[${range.join()}]&sort=["createdAt","DSC"]`,
    {
      method: 'GET',
      // body: JSON.stringify(data),
      // headers: {
      //   'Content-Type': 'application/json',
      //   Authorization: localStorage.getItem('auth') || '',
      // },
    }
  )
    .then((res) => {
      const resHeader = res.headers.get('content-range');
      const [range, total] = resHeader?.split(' ').at(-1)?.split('/') || [];
      _data = { range, total };
      return res.json();
    })
    .then((data) => {
      if (data.code == 400) {
        return [];
      }
      _data.data = data;
      console.log(data);

      return _data;
    })
    .catch((e) => {
      console.log(e);
      return [];
    });
};

export const getStats = () => {
  if (!loggedUser?.userId) return;
  return fetch(`${nodeAPIUrl}/notes/stats?userId=${loggedUser?.userId}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      // Authorization: localStorage.getItem('auth') || '',
    },
  })
    .then((res) => res.json())
    .catch((e) => {
      console.log(e);
      return {};
    });
};
