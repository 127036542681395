import { Grid } from '@mui/material';
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

const OrderCreate = () => {
  const transactionModes = [
    { id: 'CASH', name: 'Cash' },
    { id: 'CARD', name: 'Card' },
    { id: 'UPI', name: 'UPI' },
  ];

  return (
    <Create title='Create Order'>
      <SimpleForm>
        <Grid container spacing={2} rowSpacing={-1} md={6}>
          <Grid item xs={12} sm={12}>
            <ReferenceInput
              source='payeeId'
              reference='payees'
              filter={{ userId: 'offline' }}
            >
              <AutocompleteInput
                fullWidth
                label='Select Payee'
                optionText={(choice) =>
                  choice?.payerName ? `${choice.payerName}` : ''
                }
              />
            </ReferenceInput>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput
              source='settlementAmount'
              label='Settlement Amount'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <NumberInput source='totalAmount' label='Total Amount' fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source='customerName' label='Customer Name' fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput source='customerId' label='Customer ID' fullWidth />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextInput
              source='customerMobile'
              label='Customer Mobile'
              type='number'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SelectInput
              source='transactionMode'
              choices={transactionModes}
              label='Transaction Mode'
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <TextInput
              source='cardInfo'
              label='Card Information'
              multiline
              rows={2}
              fullWidth
            />
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};

export default OrderCreate;
