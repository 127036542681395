import { useState } from 'react';
import Box from '@mui/material/Box';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import SettingsIcon from '@mui/icons-material/Settings';
import DvrIcon from '@mui/icons-material/Dvr';

import {
  useTranslate,
  DashboardMenuItem,
  MenuItemLink,
  MenuProps,
  useSidebarState,
} from 'react-admin';

import payees from '../payees';
import customers from '../customers';
import orders from '../orders';
import billpayOrders from '../billpayOrders';
import SubMenu from './SubMenu';
import cards from '../cards';
import notes from '../notes';

type MenuName =
  | 'menuCatalog'
  | 'menuSales'
  | 'menuCustomers'
  | 'menuSettings'
  | 'menuMonitoring';

const Menu = ({ dense = false }: MenuProps) => {
  const [state, setState] = useState({
    menuCatalog: true,
    menuSales: true,
    menuCustomers: true,
    menuSettings: true,
    menuMonitoring: true,
  });
  const translate = useTranslate();
  const [open] = useSidebarState();

  const handleToggle = (menu: MenuName) => {
    setState((state) => ({ ...state, [menu]: !state[menu] }));
  };

  return (
    <Box
      sx={{
        width: open ? 200 : 50,
        marginTop: 1,
        marginBottom: 1,
        transition: (theme) =>
          theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
      }}
    >
      <DashboardMenuItem />
      {/* <SubMenu
        handleToggle={() => handleToggle('menuSales')}
        isOpen={state.menuSales}
        name="pos.menu.sales"
        icon={<orders.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/orders"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.commands.name`, {
            smart_count: 2,
          })}
          leftIcon={<orders.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/invoices"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.invoices.name`, {
            smart_count: 2,
          })}
          leftIcon={<invoices.icon />}
          dense={dense}
        />
      </SubMenu> */}
      <MenuItemLink
        to='/orders'
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.commands.name`, {
          smart_count: 2,
        })}
        leftIcon={<orders.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/billpayOrders'
        state={{ _scrollToTop: true }}
        primaryText='Billpay Orders'
        leftIcon={<billpayOrders.icon />}
        dense={dense}
      />

      <MenuItemLink
        to='/customers'
        state={{ _scrollToTop: true }}
        primaryText={translate(`resources.customers.name`, {
          smart_count: 2,
        })}
        leftIcon={<customers.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/cards'
        state={{ _scrollToTop: true }}
        primaryText={'Cards'}
        leftIcon={<cards.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/payees'
        state={{ _scrollToTop: true }}
        primaryText='Payees'
        leftIcon={<payees.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/notes'
        state={{ _scrollToTop: true }}
        primaryText='Notes'
        leftIcon={<notes.icon />}
        dense={dense}
      />
      <MenuItemLink
        to='/kyc'
        state={{ _scrollToTop: true }}
        primaryText='KYC List'
        leftIcon={<FingerprintIcon />}
        dense={dense}
      />

      <SubMenu
        handleToggle={() => handleToggle('menuMonitoring')}
        isOpen={state.menuMonitoring}
        name='Monitoring'
        icon={<DvrIcon />}
        dense={dense}
      >
        <MenuItemLink
          to='/user/rateLimits'
          state={{ _scrollToTop: true }}
          primaryText='API User Hits'
          dense={dense}
        />
        <MenuItemLink
          to='/apis/rateLimits'
          state={{ _scrollToTop: true }}
          primaryText='API Rate Limits'
          dense={dense}
        />
      </SubMenu>

      <SubMenu
        handleToggle={() => handleToggle('menuSettings')}
        isOpen={state.menuSettings}
        name='Settings'
        icon={<SettingsIcon />}
        dense={dense}
      >
        <MenuItemLink
          to='/merchantCodeMapping'
          state={{ _scrollToTop: true }}
          primaryText='Merchant Mappings'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/settlementMapping'
          state={{ _scrollToTop: true }}
          primaryText='Settlement Mappings'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />

        <MenuItemLink
          to='/paymentTypes'
          state={{ _scrollToTop: true }}
          primaryText='Payment Types'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/settlementTypes'
          state={{ _scrollToTop: true }}
          primaryText='Settlement Types'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/cardTypes'
          state={{ _scrollToTop: true }}
          primaryText='Card Types'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/cardBankTypes'
          state={{ _scrollToTop: true }}
          primaryText='Bank Types'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/rangeTypes'
          state={{ _scrollToTop: true }}
          primaryText='Range Types'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/banks'
          state={{ _scrollToTop: true }}
          primaryText='Banks'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/cardProviders'
          state={{ _scrollToTop: true }}
          primaryText='Card Providers'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/merchantCodes'
          state={{ _scrollToTop: true }}
          primaryText='Merchant Codes'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
        <MenuItemLink
          to='/userConfigs'
          state={{ _scrollToTop: true }}
          primaryText='User Configs'
          // leftIcon={<FingerprintIcon />}
          dense={dense}
        />
      </SubMenu>

      {/* <SubMenu
        handleToggle={() => handleToggle('menuCustomers')}
        isOpen={state.menuCustomers}
        name="pos.menu.customers"
        icon={<customers.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/customers"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.customers.name`, {
            smart_count: 2,
          })}
          leftIcon={<customers.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/payees"
          state={{ _scrollToTop: true }}
          primaryText="Payees"
          leftIcon={<payees.icon />}
          dense={dense}
        />
      </SubMenu> */}
      {/* <SubMenu
        handleToggle={() => handleToggle('menuCatalog')}
        isOpen={state.menuCatalog}
        name="pos.menu.catalog"
        icon={<products.icon />}
        dense={dense}
      >
        <MenuItemLink
          to="/products"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.products.name`, {
            smart_count: 2,
          })}
          leftIcon={<products.icon />}
          dense={dense}
        />
        <MenuItemLink
          to="/categories"
          state={{ _scrollToTop: true }}
          primaryText={translate(`resources.categories.name`, {
            smart_count: 2,
          })}
          leftIcon={<categories.icon />}
          dense={dense}
        />
      </SubMenu> */}
      {/* <MenuItemLink
                to="/reviews"
                state={{ _scrollToTop: true }}
                primaryText={translate(`resources.reviews.name`, {
                    smart_count: 2,
                })}
                leftIcon={<reviews.icon />}
                dense={dense}
            /> */}
    </Box>
  );
};

export default Menu;
